// Import Angular and related items
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// Import models
import { ChangePassword } from '@core/models/change-password';
import { User } from '@core/models/user';

// Import other files
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  //#region variables

  rootUrl = environment.commonApiRootUrl + '/User/';
  userCache = {};

  //#endregion

  //#region constructor

  constructor(private http: HttpClient) { }

  //#endregion

  //#region HTTP Gets

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.rootUrl + 'GetAllUsers');
  }

  getAssignedUsersByRole(roleId: number): Observable<User[]> {
    return this.http.get<User[]>(this.rootUrl + `GetAssignedUsersByRole?roleId=${roleId}`);
  }

  getAvailableUsersByRole(roleId: number): Observable<User[]> {
    return this.http.get<User[]>(this.rootUrl + `GetAvailableUsersByRole?roleId=${roleId}`);
  }

  getAvailableUsersByTag(tagId: number): Observable<User[]> {
    return this.http.get<User[]>(this.rootUrl + `GetAvailableUsersByTag?tagId=${tagId}`);
  }

  getUsers(lastName: string = undefined, active: boolean = true, isInternalUser: boolean = undefined, isIncognitoUser: boolean = undefined): Observable<User[]> {
    let firstFilterApplied = false;
    let url = this.rootUrl + `GetUsers`;

    if (lastName) {
      if (!firstFilterApplied) {
        url += `?lastName=${lastName}`;
        firstFilterApplied = true;
      } else {
        url += `&lastName=${lastName}`;
      }
    } else {
      lastName = null;
    }

    if (active) {
      if (!firstFilterApplied) {
        url += `?active=${active}`;
        firstFilterApplied = true;
      } else {
        url += `&active=${active}`;
      }
    } else {
      active = null;
    }

    if (isInternalUser) {
      if (!firstFilterApplied) {
        url += `?isInternalUser=${isInternalUser}`;
        firstFilterApplied = true;
      } else {
        url += `&isInternalUser=${isInternalUser}`;
      }
    } else {
      isInternalUser = null;
    }

    if (isIncognitoUser) {
      if (!firstFilterApplied) {
        url += `?isIncognitoUser=${isIncognitoUser}`;
        firstFilterApplied = true;
      } else {
        url += `&isIncognitoUser=${isIncognitoUser}`;
      }
    } else {
      isIncognitoUser = null;
    }

    return this.http.get<User[]>(url);
  }

  getUsersFiltered(roles: string, tags: string): Observable<User[]> {
    // Check if we've cached a user call with these same parameters.  If we have, return that.
    if (this.userCache[roles + '|' + tags]) {
      return of(this.userCache[roles + '|' + tags]);
    } else {
      // Otherwise, load the users from the database, and cache them.
      let filter = '';
      let filtercount = 0;
      if (roles != null && roles !== '') {
        filter = '?roles=' + roles;
        filtercount = 1;
      }
      if (tags != null && tags !== '') {
        if (filtercount > 0) {
          filter += '&';
        } else {
          filter += '?';
        }
        filter += 'tags=' + tags;
        filtercount += 1;
      }

      const observable = this.http.get<User[]>(this.rootUrl + filter).pipe(
        map(users => {
          return this.userCache[roles + '|' + tags] = users;
        })
      );
      return observable;
    }
  }

  //#endregion

  //#region HTTP Posts

  insertUser(user: User): Observable<User>{
    return this.http.post<User>(this.rootUrl + 'InsertUser/', user);
  }

  toggleUserActiveStatus(user: User): Observable<User>{
    return this.http.post<User>(this.rootUrl + 'ToggleUserActiveStatus/', { user });
  }

  //#endregion

  //#region HTTP Puts

  resetPassword(password: string, userId: number): Observable<boolean> {
    const passwordChange: ChangePassword = new ChangePassword();
    passwordChange.userID = userId;
    passwordChange.newPassword = password;

    return this.http.put<boolean>(this.rootUrl + passwordChange.userID, passwordChange);
  }

  updateUser(user: User): Observable<User>{
    return this.http.put<User>(this.rootUrl + 'UpdateUser/', user);
  }

  //#endregion
}