import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EditProjectComponent } from '@wireline/components/edit-project/edit-project.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  public open = true;
  constructor(public dialogRef: MatDialogRef<FeedbackComponent>) { }

  ngOnInit() {
  }

  closeDialog(source?: any): void {
    this.dialogRef.close(source);
  }

}
