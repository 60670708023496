// Import Angular and related items
import { Injectable } from '@angular/core';
import { Toaster, ToastConfig } from 'ngx-toast-notifications';

// Import components
import { ToastrNotificationComponent } from './toastr-notification.component';

@Injectable({
  providedIn: 'root'
})
export class ToastrNotificationService {

  constructor(private toastr: Toaster) { }

  error(message, config?: ToastConfig) {
    config = config ? config : {};
    config.type = 'danger';
    config.autoClose = config.duration ? true : false;
    config.preventDuplicates = true;
    config.position = config.position ?? 'top-left';
    return this.open(message, config);
  }

  info(message, config?: ToastConfig) {
    config = config ? config : {};
    config.type = 'info';
    config.autoClose = config.autoClose;
    config.duration = config.autoClose ? config.duration ? config.duration : 3000 : null;
    return this.open(message, config);
  }

  success(message, config?: ToastConfig) {
    config = config ? config : {};
    config.type = 'success';
    config.duration = config.duration ? config.duration : 3000;
    config.position = config.position;
    return this.open(message, config);
  }

  warning(message, config?: ToastConfig) {
    config = config ? config : {};
    config.type = 'warning';
    config.duration = config.duration ? config.duration : 5000;
    return this.open(message, config);
  }

  open(message: string, config?: ToastConfig) {
    config = {
      ...config,
      type: config.type,
      text: message,
      component: ToastrNotificationComponent,
      position: config.position ?? 'top-center'
    };
    return this.toastr.open(config);
  }
}