// Import Angular and Related items
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Import services
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkAccess(url);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = route.path;
    return this.checkAccess(url);
  }

  checkAccess(url: string): boolean {
    if (this.authService.CurrentUser.userRoles.some(role => (role.roleCode === 'fulfillment'))) {
      return true;
    }

    this.authService.redirectUrl = url;

    this.router.navigate(['accessForbidden']);
    return false;
  }
}