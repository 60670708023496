<mat-card class="toast-card">
    <mat-card-header>
        <div mat-card-avatar class="alert-icon">
            <mat-icon *ngIf="type === 'danger'" class="toastIcon">error</mat-icon>
            <mat-icon *ngIf="type === 'warning'" class="toastIcon">warning</mat-icon>
            <mat-icon *ngIf="type === 'info'" class="toastIcon">info</mat-icon>
            <mat-icon *ngIf="type === 'success'" class="toastIcon">check</mat-icon>
        </div>
    </mat-card-header>
    <mat-card-content class="snackbar">
        <div class="message">
            {{toast.text}}
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="toastr-action">
            <button mat-button (click)="action()" class="action-text">
                <mat-icon class="action-icon">close</mat-icon>
                Close
            </button>
        </div>
    </mat-card-actions>
</mat-card>