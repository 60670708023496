import { Component, OnInit, Input, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pt-custom-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {

  @Input() titleIcon: string = null;
  @Input() dialogType: 'default' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' = 'default';
  @Input() notes: string[];
  @Input() hasPanel = false;
  @Input() fullWidth = false;

  @HostBinding('class.has-title-icon') public _hasTitleIcon = false;
  @HostBinding('class.has-notes') public _hasNotes = false;
  @HostBinding('class.paneled') public _hasPanel = false;
  @HostBinding('class.default') public _isDefault = false;
  @HostBinding('class.primary') public _isPrimary = false;
  @HostBinding('class.secondary') public _isSecondary = false;
  @HostBinding('class.success') public _isSuccess = false;
  @HostBinding('class.danger') public _isDanger = false;
  @HostBinding('class.warning') public _isWarning = false;
  @HostBinding('class.info') public _isInfo = false;
  @HostBinding('class.full-width') public _fullWidth = false;

  ngOnInit(): void {
    this._hasTitleIcon = this.titleIcon !== null && this.titleIcon.length > 0;
    this._hasNotes = this.notes && this.notes.length > 0;
    this._hasPanel = this.hasPanel;
    this._isDefault = this.dialogType === 'default';
    this._isPrimary = this.dialogType === 'primary';
    this._isSecondary = this.dialogType === 'secondary';
    this._isSuccess = this.dialogType === 'success';
    this._isDanger = this.dialogType === 'danger';
    this._isWarning = this.dialogType === 'warning';
    this._isInfo = this.dialogType === 'info';
    this._fullWidth = this.fullWidth;
  }
}