<pt-custom-dialog dialogType="success">
    <div dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        Feedback Instructions 
        <a href="mailto:devops@prestotech.net">
            <mat-icon class="mailIcon">mail</mat-icon>
        </a>
    </div>
    <div dialog-content>
        <div mat-dialog-content>
            <ul>
                <li>To give precise feedback, please screenshot URL of the tab where the error occurred.</li>
                <li>Before taking the screenshot, please press the F12 key to bring up the developer tools window, and click the console tab.</li>
                <li>Move this popup out of the way when taking a screenshot.</li>
                <li>To move the popup, put your mouse pointer on the title and drag the popup.</li>
                <li>Please provide a detailed list of the steps you took when you encountered the error.</li>
                <li>Make the subject line Prestotech Goliath Application Error.</li>
                <li>When you are ready to write the email, click the mail icon.</li>
                <li>If you are having trouble with the mail icon, you can email: devops@prestotech.net with your feedback instead.</li>
            </ul>
        </div>
    </div>
    <div dialog-footer>
        <div mat-dialog-actions>
            <button mat-button (click)="closeDialog()">
                Close                
            </button>
        </div>
    </div>
</pt-custom-dialog>