import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';

import { throwError, BehaviorSubject, Observable } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { retry, catchError, switchMap, take, filter, finalize } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService) { }

    addHeaders(request: HttpRequest<any>): HttpRequest<any> {
      return request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.authService.Token}`)
      });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If we don't have the user's token or this is an asset, don't do anything...
        // Otherwise, clone the request, add the authrization header, and return it.
        if (this.authService.Token != null && !request.url.startsWith('assets/')) {
          request = this.addHeaders(request);
        }

        return next.handle(request).pipe(
          retry(1),
          catchError((err: HttpErrorResponse) => {
            // If we're trying to refresh the sign in and it fails, "log out" the user
            if (request.url.includes('refresh')) {
                this.authService.logout();
            }

            // If an error other than 401 occurs, let's freak out about it
            if (err.status !== 401) {
                return throwError(err);
            }

            // If we're already trying to refresh the token, wait until that's finished
            if (this.refreshTokenInProgress) {
                return this.refreshTokenSubject.pipe(
                    filter(result => result != null),
                    take(1),
                    switchMap(() => next.handle(this.addHeaders(request)))
                );
            } else {
                this.refreshTokenInProgress = true;
                this.refreshTokenSubject.next(null);

                // Refresh the token
                return this.authService.refreshToken().pipe(
                    switchMap(result => {
                        // If we were able to get a new token, re-send the request with that new token
                        if (result.success) {
                            this.refreshTokenSubject.next(result.result.token);
                            return next.handle(this.addHeaders(request));
                        } else {
                            // Otherwise, the user needs to log in again.
                            this.authService.logout();
                            return throwError(err);
                        }
                    }),
                    finalize(() => this.refreshTokenInProgress = false)
                );
            }
        }));
    }
}