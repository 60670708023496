// Import Angular and related items
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Import services
import { AuthService } from '@core/services/auth.service';
import { ToastrNotificationService } from '@shared/components/toastr-notification/toastr-notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  capsOn;
  error = false;
  message: string;
  password: string;
  signinpressed = false;
  username: string;

  private componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild('psswrd', { static: true }) passwordFail: ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrNotificationService: ToastrNotificationService
  ) { }

  ngOnInit() {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
    } else {
      alert('Please use Chrome');
    }
    this.checkLoginStatus();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  checkLoginStatus() {
    if (this.authService.isLoggedIn) {
      location.assign('home');
    }
  }

  login() {
    this.signinpressed = true;
    this.authService.login(this.username, this.password).pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
      if (result.success) {
        this.router.navigate(['home']);
      } else {
        this.toastrNotificationService.error(`Couldn't log in as ${this.username}`);
        this.signinpressed = false;
        this.password = '';
        setTimeout(() => this.passwordFail.nativeElement.focus(), 1);
        this.message = result.errorMessage;
      }
    });
  }
}