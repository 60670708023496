export const environment = {
    debug: false,
    production: false,
    apiRoot: 'https://api.prestotech.dev',
    commonApiRootUrl: 'https://api.prestotech.dev/common',
    fulfillmentApiRootUrl: 'https://api.prestotech.dev/fulfillment',
    mobilityApiRootUrl: 'https://api.prestotech.dev/mobility',
    podApiRootUrl: 'https://api.prestotech.dev/pod',
    sysadminApiRootUrl: 'https://api.prestotech.dev/sysadmin',
    wirelineApiRootUrl: 'https://api.prestotech.dev/wireline',
    name: 'dev'
};