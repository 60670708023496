<div class="dialog-header" mat-dialog-title>
	<div class="title">
		<ng-content select="[dialog-title]"></ng-content>
	</div>
</div>

<div class="dialog-content" mat-dialog-content>
	<ng-content select="[dialog-content]"></ng-content>
</div>

<div class="dialog-footer">
	<ng-content select="[dialog-footer]"></ng-content>
</div>