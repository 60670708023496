// Import Angular and related items
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Import services
import { AuthService } from '@core/services/auth.service';
import { OverlayService } from '@shared/services/overlay.service';

// Import components
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FeedbackComponent } from './components/feedback/feedback.component';

// Import other files
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  environmentName = environment.name;
  environmentMessage: string;
  projectVersion = require('../../package.json').version;
  title = 'Goliath';

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private overlayService: OverlayService,
    private router: Router
  ) { }

  ngOnInit() {
    this.overlayService.currentPortalOverlayClosedStatus.pipe(takeUntil(this.componentDestroyed$)).subscribe(status => {
      if (status === true) {
        this.overlayService.applicationLoaderOverlay.dispose();
      } else {
        return;
      }
    });
    console.log('Is Local Environment: ', environment.name);
    this.environmentMessage = environment.name === 'local' || environment.name === 'dev'
      ? 'Development Environment'
      : environment.name === 'qa'
        ? 'Test Environment' : '';
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  feedback() {
    this.dialog.closeAll();
    this.dialog.open(FeedbackComponent, {
      width: '30%',
      maxWidth: '40%',
      panelClass: [
        'rounded-dialog-window',
        'padless-dialog'
      ],
      autoFocus: false,
      position: {
        top: '0',
      },
      disableClose: false,
      hasBackdrop: false
    });
  }

  goHome() {
    this.router.navigate(['home']);
  }

  openApplicationAreaOverlay() {
    this.overlayService.openOverlay();
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '30%',
      maxWidth: '40%',
      panelClass: [
        'rounded-dialog-window',
        'padless-dialog'
      ],
      data: {
        userId: this.authService.CurrentUser.userId
      },
      autoFocus: false,
      position: {
        top: '0',
      },
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.componentDestroyed$)).subscribe();
  }

  signOut(): void {
    this.authService.logout();
  }
}
