<pt-custom-dialog dialogType="primary">
    <div dialog-title>
        <button mat-button (click)="close()" class="closeButton">
            <mat-icon>close</mat-icon>
        </button>
        Change Password
    </div>
    <div dialog-content>
        <form>
            <mat-card class="sign-in-card">
                <mat-card-header>
                    <mat-icon mat-card-avatar class="example-header-image">supervised_user_circle</mat-icon>
                    <mat-card-title class="title">Change Password Form</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div (capsLock)="capsOn=$event">
                        <mat-form-field class="full-width">
                            <input id="pass" tabindex="0" type="{{!showPassVal ? 'password' : 'text'}}" matInput placeholder="New Password" [(ngModel)]="password" name="password" #psswrd>
                            <button tabindex="-1" [ngClass]="!showPassVal ? 'unmask' : 'remask'" type="button" title="{{!showPassVal ? 'Show Password' : 'Hide Password'}}" (click)="showPass()"></button>
                        </mat-form-field>
              
                        <mat-form-field class="full-width">
                            <input id="pass2" tabindex="0" type="{{!showRePassVal ? 'password' : 'text'}}" matInput placeholder="Retype New Password" [(ngModel)]="repassword" name="repassword" #psswrd>
                            <button tabindex="-1" [ngClass]="!showRePassVal ? 'unmask' : 'remask'" type="button" title="{{!showRePassVal ? 'Show Password' : 'Hide Password'}}" (click)="showRePass()"></button>
                        </mat-form-field>

                        <p id="warning" *ngIf="capsOn" class="capsLockOn">* Caps lock is on</p>
                        <p *ngIf="passwordMismatch" class="error">* Please make sure your passwords match and that they are not blank.</p>
                    </div>
                </mat-card-content>
                <mat-card-actions class="align-rt">
                    <mat-progress-bar mode="indeterminate" *ngIf="changePasswordPressed" class="tool-progress"></mat-progress-bar>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
    <div dialog-footer>
        <div mat-dialog-actions>
            <button type="button" tabindex="0" mat-button (click)="close()">Cancel</button>
            <button tabindex="0" mat-button type="submit" (click)="changePassword(password, repassword)" [disabled]="!password || !repassword || password === '' || repassword === ''">Save</button>
        </div>
    </div>
</pt-custom-dialog>