// Import Angular and related items
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

// Import models
import { PortalIcon } from '@models/shared/portal-icon';

// Import services
import { AuthService } from '@core/services/auth.service';
import { OverlayService } from '@shared/services/overlay.service';

@Component({
  selector: 'des-application-portal',
  templateUrl: './application-portal.component.html',
  styleUrls: ['./application-portal.component.scss']
})
export class ApplicationPortalComponent implements OnInit, OnDestroy {
  iconList: PortalIcon[] = [
    {
      name: 'Wireline',
      featureName: 'Wireline',
      matIconName: 'show_chart',
      textColor: 'white',
      backgroundColor: 'orange'
    },
    {
      name: 'Mobility',
      featureName: 'Mobility',
      matIconName: 'phone_android',
      textColor: 'white',
      backgroundColor: '#3E8E41'
    },
    {
      name: 'Fulfillment/Inventory',
      featureName: 'Fulfillment/Inventory',
      matIconName: 'list_alt',
      textColor: 'white',
      backgroundColor: 'black'
    },
    {
      name: 'Steve',
      featureName: 'Steve\'s Stuff',
      matIconName: 'perm_identity',
      textColor: 'white',
      backgroundColor: 'brown'
    },
    {
      name: 'Procurement',
      featureName: 'Procurement',
      matIconName: 'shopping_cart',
      textColor: 'white',
      backgroundColor: 'slateblue'
    },
    {
      name: 'SysAdmin',
      featureName: 'Sys Admin',
      matIconName: 'admin_panel_settings',
      textColor: 'white',
      backgroundColor: '#0E5FAC'
    },
    {
      name: 'Admin',
      featureName: 'Goliath Admin',
      matIconName: 'security',
      textColor: 'white',
      backgroundColor: '#ff5722'
    },
    {
      name: 'AlliedUniversal',
      featureName: 'Allied Universal',
      matIconName: 'business_center',
      textColor: 'white',
      backgroundColor: '#64b5f6'
    }
  ];
  userIcons: PortalIcon[] = [];

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private router: Router
  ) { }

  ngOnInit() {
    this.populateUserIconsList(this.iconList);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  navigateToApplicationArea(areaName: string) {
    this.overlayService.pushUpdatedPortalOverlayClosedStatus();

    switch (areaName) {
      case 'Wireline':
        this.router.navigate(['wireline']);
        break;
      case 'Mobility':
        this.router.navigate(['mobility']);
        break;
      case 'Fulfillment/Inventory':
        this.router.navigate(['fulfillment']);
        break;
      case 'Steve':
        this.router.navigate(['steves-stuff']);
        break;
      case 'Procurement':
        this.router.navigate(['procurement']);
        break;
      case 'SysAdmin':
        this.router.navigate(['sysadmin']);
        break;
      case 'Admin':
        this.router.navigate(['admin']);
        break;
      case 'AlliedUniversal':
        this.router.navigate(['alliedUniversal']);
        break;
      default:
        this.router.navigate(['home']);
        break;
    }
  }

  populateUserIconsList(iconList: PortalIcon[]) {
    for (const icon of iconList) {
      switch (icon.name) {
        case 'Wireline':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'wireln')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'Mobility':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'mobility')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'Fulfillment/Inventory':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'fulfillment')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'Steve':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'steve')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'Procurement':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'procurement')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'SysAdmin':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'sysAdmin')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'Admin':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'masterAdmin')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        case 'AlliedUniversal':
          if (this.authService.CurrentUser.userRoles.some(role => role.roleCode === 'alliedUniversal')) {
            this.userIcons.push(icon);
            break;
          }
          break;
        default:
          return false;
      }
    }
  }
}