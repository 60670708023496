// Import Angular and related items
import { Output, HostListener, EventEmitter } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[capsLock]'
})
export class TrackCapsDirective {
  // Code Reference: https://stackoverflow.com/questions/45035330/detect-and-warn-users-about-caps-lock-is-on
  @Output() capsLock = new EventEmitter<boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
}