// Import Angular and related items
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

// Import components
import { ApplicationPortalComponent } from '@shared/components/application-portal/application-portal.component';

@Injectable({
  providedIn: 'root'
})
export class OverlayService implements OnDestroy {
  applicationLoaderOverlay: OverlayRef;

  private onDestroy$ = new Subject<boolean>();
  portalOverlayClosedStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  currentPortalOverlayClosedStatus = this.portalOverlayClosedStatus$.asObservable().pipe(takeUntil(this.onDestroy$));

  constructor(public overlay: Overlay) { }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  openOverlay() {
    const positionStrategy = this.overlay.position().global().centerHorizontally();

    const config = new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      panelClass: 'overlay-pane'
    });

    this.applicationLoaderOverlay = this.overlay.create(config);
    const applicationAreaPortal = new ComponentPortal(ApplicationPortalComponent);
    this.applicationLoaderOverlay.attach(applicationAreaPortal);
    this.applicationLoaderOverlay.backdropClick().pipe(takeUntil(this.onDestroy$)).subscribe(_ => this.applicationLoaderOverlay.dispose());
  }

  pushUpdatedPortalOverlayClosedStatus() {
    this.portalOverlayClosedStatus$.next(true);
  }
}