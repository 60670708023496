// Import Angular and related items
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

// Import services
import { AuthService } from '@core/services/auth.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.validateToken();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private validateToken() {
    let now = new Date();
    const validTo = new Date(this.authService?.ValidTo);
    const validFrom = new Date(this.authService?.ValidFrom);
    const yesterday = new Date(now.getTime() - (1000 * 60 * 60 * 24));
    const fifteenMinutesFromNow = new Date(now.getTime() + (1000 * 60 * 15));

    if (validTo < now) {
      if (validFrom < yesterday) {
        this.authService.logout();
      } else {
        if (validTo < fifteenMinutesFromNow) {
          this.authService.refreshToken().pipe(takeUntil(this.componentDestroyed$)).subscribe();
        }
      }
    }
  }
}