import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Toast } from 'ngx-toast-notifications';

@Component({
  selector: 'app-toastr-notification',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './toastr-notification.component.html',
  styleUrls: ['./toastr-notification.component.scss']
})
export class ToastrNotificationComponent implements OnInit {
  type: string;
  @Input() toast: Toast;

  constructor() { }

  ngOnInit() {
    this.type = this.toast.type;
  }

  action(): void {
    this.toast.close();
  }
}