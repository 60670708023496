<form (ngSubmit)="login()">
    <mat-card class="sign-in-card">
        <mat-card-header>
            <mat-icon mat-card-avatar class="example-header-image">supervised_user_circle</mat-icon>
            <mat-card-title>Sign In</mat-card-title>
            <mat-card-subtitle>Use your PrestoTech API Credentials</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div (capsLock)="capsOn=$event">
                <mat-form-field class="full-width">
                    <input matInput autofocus placeholder="Username" [(ngModel)]="username" name="username">
                </mat-form-field>
    
                <mat-form-field class="full-width">
                    <input id="pass" type="password" matInput placeholder="Password" [(ngModel)]="password" name="password" #psswrd>             
                </mat-form-field>
    
                <p id="warning" *ngIf="capsOn" class="capsLockOn">* Caps lock is on</p>
                <p *ngIf="message" class="error">{{message}}</p>
            </div>
        </mat-card-content>
        <mat-card-actions class="align-rt">
            <button mat-button type="submit">Sign In</button>
            <mat-progress-bar mode="indeterminate" *ngIf="signinpressed" class="tool-progress"></mat-progress-bar>
        </mat-card-actions>        
    </mat-card>
</form>

<p class="align-ct subtle">Login Form</p>
<p class="align-ct">Not looking for the Prestotech Internal App Portal? <br> Try <a href="http://prestotech.net/">the PrestoTech Wesbite</a>.</p>