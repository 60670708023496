// Import modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { ToastNotificationsModule } from 'ngx-toast-notifications';

// Import Components
import { AccessForbiddenComponent } from './components/access-forbidden/access-forbidden.component';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

// Import Providers
import { AuthorizationInterceptor } from './interceptors/auth-interceptor';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        ChangePasswordComponent,
        AccessForbiddenComponent,
        FeedbackComponent,
        HomeComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        HttpClientModule,
        SharedModule,
        ToastNotificationsModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true, },
        CurrencyPipe,
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
