// Import Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { NgChartsModule } from 'ng2-charts'

// Import Components
import { ActivationsComponent } from './components/reports/allied-universal/sla/components/activations/activations.component';
import { AddCompanyAddressComponent } from './components/add-company-address/add-company-address.component';
import { AddCompanyContactComponent } from './components/company-contact/components/add-company-contact/add-company-contact.component';
import { AddCompanyDialogComponent } from './components/add-company-dialog/add-company-dialog.component';
import { AddCompanyProductTagComponent } from './components/company-product-tag/components/add-company-product-tag/add-company-product-tag.component';
import { AddCustomerOrderComponent } from './components/customer-order/components/add-customer-order/add-customer-order.component';
import { AddGiftBasketComponent } from './components/gift-basket/components/add-gift-basket/add-gift-basket.component';
import { AddGoliathProductQuotewerksProductMapComponent } from '../shared/components/inventory/map-goliath-product-to-quotewerks-product/components/add-goliath-product-quotewerks-product-map/add-goliath-product-quotewerks-product-map.component';
import { AddVendorAddressComponent } from './components/vendor-management/components/vendor-address/components/add-vendor-address/add-vendor-address.component';
import { AddVendorComponent } from './components/vendor-management/components/vendor/components/add-vendor/add-vendor.component';
import { AddVendorContactComponent } from './components/vendor-management/components/vendor-contact/components/add-vendor-contact/add-vendor-contact.component';
import { AdvancedFiltersDialogComponent } from './components/advanced-filters-dialog/advanced-filters-dialog.component';
import { AllCompanyContactsComponent } from './components/company-contact/components/all-company-contacts/all-company-contacts.component';
import { AllCompanyProductTagsComponent } from './components/company-product-tag/components/all-company-product-tags/all-company-product-tags.component';
import { AllCustomerOrdersComponent } from './components/customer-order/components/all-customer-orders/all-customer-orders.component';
import { AllGiftBasketsComponent } from './components/gift-basket/components/all-gift-baskets/all-gift-baskets.component';
import { AllGoliathProductToQuotewerksProductMapsComponent } from '../shared/components/inventory/map-goliath-product-to-quotewerks-product/components/all-goliath-product-to-quotewerks-product-maps/all-goliath-product-to-quotewerks-product-maps.component';
import { AllMobilityForecastCutoffDatesComponent } from './components/mobility-forecast-cutoff-date/components/mobility-all-forecast-cutoff-dates/mobility-all-forecast-cutoff-dates.component';
import { AllProductProcurementInformationComponent } from './components/product-procurement-information/components/all-product-procurement-information/all-product-procurement-information.component';
import { AllStagedSalesOrdersComponent } from '../shared/components/staged-sales-order/components/all-staged-sales-orders/all-staged-sales-orders.component';
import { AllVendorAddressesComponent } from './components/vendor-management/components/vendor-address/components/all-vendor-addresses/all-vendor-addresses.component';
import { AllVendorContactsComponent } from './components/vendor-management/components/vendor-contact/components/all-vendor-contacts/all-vendor-contacts.component';
import { AllVendorsComponent } from './components/vendor-management/components/vendor/components/all-vendors/all-vendors.component';
import { AllWirelineForecastCutoffDatesComponent } from './components/wireline-forecast-cutoff-date/components/wireline-all-forecast-cutoff-dates/wireline-all-forecast-cutoff-dates.component';
import { ApplicationPortalComponent } from './components/application-portal/application-portal.component';
import { BookOfBusinessSummaryContainerComponent } from './components/reports/forecasting/book-of-business-summary/book-of-business-summary-container/book-of-business-summary-container.component';
import { CompanyContactInformationComponent } from './components/company-contact/components/company-contact-information/company-contact-information.component';
import { ColumnSelectDialogComponent } from './components/column-select-dialog/column-select-dialog.component';
import { CompaniesLookupComponent } from '../shared/components/data-lookups/components/shared/companies/companies-lookup.component';
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { CopyForecastEntryComponent } from './components/forecasting/copy-forecast-entry/copy-forecast-entry.component';
import { CountriesLookupComponent } from '../shared/components/data-lookups/components/shared/countries/countries-lookup.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { CurrentInventoryComponent } from './components/reports/allied-universal/summary/components/current-inventory/current-inventory.component';
import { CustomerManagementComponent } from './components/customer-management/customer-management.component';
import { CustomerOrderInformationComponent } from './components/customer-order/components/customer-order-information/customer-order-information.component';
import { CustomerOrderStatusNoteDialogComponent } from './components/customer-order/components/customer-order-status-note-dialog/customer-order-status-note-dialog.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EditableComponent } from './components/editable/editable.component';
import { GenerateSalesOrderComponent } from '../shared/components/staged-sales-order/components/generate-sales-order/generate-sales-order.component';
import { GiftBasketInformationComponent } from './components/gift-basket/components/gift-basket-information/gift-basket-information.component';
import { GoliathProductQuotewerksProductMapInformationComponent } from '../shared/components/inventory/map-goliath-product-to-quotewerks-product/components/goliath-product-quotewerks-product-map-information/goliath-product-quotewerks-product-map-information.component';
import { IncomingSerialNumbersReportComponent } from './components/reports/fulfillment/incoming-serial-numbers-report/incoming-serial-numbers-report.component';
import { InventoryCountDetailReportComponent } from '../shared/components/inventory/on-hand-inventory-search/components/inventory-count-detail-report/inventory-count-detail-report.component';
import { InventoryCountReportComponent } from '../shared/components/inventory/on-hand-inventory-search/components/inventory-count-report/inventory-count-report.component';
import { InvoiceReportComponent } from './components/reports/fulfillment/invoice-report/invoice-report.component';
import { MobilityAddSalesLeadQuotaComponent } from './components/mobility-sales-lead-quota/components/mobility-add-sales-lead-quota/mobility-add-sales-lead-quota.component';
import { MobilityAllForecastContainerComponent } from './components/mobility-all-forecasts/components/mobility-all-forecast-container/mobility-all-forecast-container.component';
import { MobilityAllForecastDetailComponent } from './components/mobility-all-forecasts/components/mobility-all-forecast-detail/mobility-all-forecast-detail.component';
import { MobilityAllForecastsReportComponent } from './components/reports/forecasting/mobility-all-forecasts-report/mobility-all-forecasts-report.component';
import { MobilityAllForecastSummaryComponent } from './components/mobility-all-forecasts/components/mobility-all-forecast-summary/mobility-all-forecast-summary.component';
import { MobilityAllSalesLeadQuotasComponent } from './components/mobility-sales-lead-quota/components/mobility-all-sales-lead-quotas/mobility-all-sales-lead-quotas.component';
import { MobilityBookOfBusinessSummaryComponent } from './components/reports/forecasting/book-of-business-summary/mobility-book-of-business-summary/mobility-book-of-business-summary.component';
import { MobilitySalesLeadQuotaInformationComponent } from './components/mobility-sales-lead-quota/components/mobility-sales-lead-quota-information/mobility-sales-lead-quota-information.component';
import { MobilityUpdateSalesLeadQuotaComponent } from './components/mobility-sales-lead-quota/components/mobility-update-sales-lead-quota/mobility-update-sales-lead-quota.component';
import { MobilityYtdForecastResultsComponent } from '@shared/components/ytd-forecast-results/components/mobility-ytd-forecast-results/mobility-ytd-forecast-results.component';
import { NightlyShipmentsReportComponent } from './components/reports/fulfillment/nightly-shipments-report/nightly-shipments-report.component';
import { OnHandInventorySearchComponent } from '../shared/components/inventory/on-hand-inventory-search/on-hand-inventory-search.component';
import { OrderTypeCountsComponent } from './components/reports/allied-universal/summary/components/order-type-counts/order-type-counts.component';
import { OrdersWithoutTrackingNumberComponent } from './components/reports/fulfillment/orders-without-tracking-number/orders-without-tracking-number.component';
import { OutgoingSerialNumbersReportComponent } from './components/reports/fulfillment/outgoing-serial-numbers-report/outgoing-serial-numbers-report.component';
import { PodComponent } from './components/pod/pod.component';
import { ProductProcurementInformationReportComponent } from './components/reports/shared/product-procurement-information-report/product-procurement-information-report.component';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { ReassignForecastEntryComponent } from './components/forecasting/reassign-forecast-entry/reassign-forecast-entry.component';
import { ReceivedQuantityMismatchDialogComponent } from '../shared/components/received-quantity-mismatch-dialog/received-quantity-mismatch-dialog.component';
import { ReportContainerComponent } from './components/report-container/report-container.component';
import { RequestsReceivedComponent } from './components/reports/allied-universal/summary/components/requests-received/requests-received.component';
import { SalesToInvoiceComponent } from './components/reports/forecasting/sales-to-invoice/sales-to-invoice.component';
import { SerialNumberSearchComponent } from '../shared/components/serial-number-search/serial-number-search.component';
import { ShipmentTrackingHistoryComponent } from './components/shipment-tracking-history/shipment-tracking-history.component';
import { ShippingChargesReportComponent } from './components/reports/fulfillment/shipping-charges-report/shipping-charges-report.component';
import { SimpleChangesDialogComponent } from './components/simple-changes-dialog/simple-changes-dialog.component';
import { SlaComponent } from './components/reports/allied-universal/sla/sla.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SpeedSelectComponent } from './components/speed-select/speed-select.component';
import { StagedSalesOrderDetailHistoryComponent } from '../shared/components/staged-sales-order/components/staged-sales-order-detail-history/staged-sales-order-detail-history.component';
import { StateSelectComponent } from './components/state-select/state-select.component';
import { StatesLookupComponent } from '../shared/components/data-lookups/components/shared/states/states-lookup.component';
import { SummaryComponent } from './components/reports/allied-universal/summary/summary.component';
import { TabGroupComponent } from './components/tab-group/tab-group.component';
import { TimeFromManagerApprovedToEndUserReceivedComponent } from './components/reports/allied-universal/sla/components/time-from-manager-approved-to-end-user-received/time-from-manager-approved-to-end-user-received.component';
import { TimeFromManagerApprovedToIntratemShippedComponent } from './components/reports/allied-universal/sla/components/time-from-manager-approved-to-intratem-shipped/time-from-manager-approved-to-intratem-shipped.component';
import { ToastrNotificationComponent } from './components/toastr-notification/toastr-notification.component';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { UpdateCompanyContactComponent } from './components/company-contact/components/update-company-contact/update-company-contact.component';
import { UpdateCustomerOrderComponent } from './components/customer-order/components/update-customer-order/update-customer-order.component';
import { UpdateGiftBasketComponent } from './components/gift-basket/components/update-gift-basket/update-gift-basket.component';
import { UpdateGoliathProductQuotewerksProductMapComponent } from '../shared/components/inventory/map-goliath-product-to-quotewerks-product/components/update-goliath-product-quotewerks-product-map/update-goliath-product-quotewerks-product-map.component';
import { UpdateMobilityForecastCutoffDateComponent } from './components/mobility-forecast-cutoff-date/components/mobility-update-forecast-cutoff-date/mobility-update-forecast-cutoff-date.component';
import { UpdateProductProcurementInformationComponent } from './components/product-procurement-information/components/update-product-procurement-information/update-product-procurement-information.component';
import { UpdateStagedSalesOrderComponent } from '../shared/components/staged-sales-order/components/update-staged-sales-order/update-staged-sales-order.component';
import { UpdateVendorComponent } from './components/vendor-management/components/vendor/components/update-vendor/update-vendor.component';
import { UpdateVendorContactComponent } from './components/vendor-management/components/vendor-contact/components/update-vendor-contact/update-vendor-contact.component';
import { UpdateWirelineForecastCutoffDateComponent } from './components/wireline-forecast-cutoff-date/components/wireline-update-forecast-cutoff-date/wireline-update-forecast-cutoff-date.component';
import { UploadAlliedActivationDataComponent } from './components/upload-allied-activation-data/upload-allied-activation-data.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { UsersLookupComponent } from '../shared/components/data-lookups/components/shared/users/users-lookup.component';
import { VendorContactInformationComponent } from './components/vendor-management/components/vendor-contact/components/vendor-contact-information/vendor-contact-information.component';
import { VendorInformationComponent } from './components/vendor-management/components/vendor/components/vendor-information/vendor-information.component';
import { VendorManagementComponent } from './components/vendor-management/vendor-management/vendor-management.component';
import { WeeklyOrdersComponent } from './components/reports/allied-universal/weekly-orders/weekly-orders.component';
import { WirelineAddSalesLeadQuotaComponent } from './components/wireline-sales-lead-quota/components/wireline-add-sales-lead-quota/wireline-add-sales-lead-quota.component';
import { WirelineAllForecastsReportComponent } from './components/reports/forecasting/wireline-all-forecasts/wireline-all-forecasts.component';
import { WirelineAllForecastContainerComponent } from './components/wireline-all-forecasts/components/wireline-all-forecast-container/wireline-all-forecast-container.component';
import { WirelineAllForecastDetailComponent } from './components/wireline-all-forecasts/components/wireline-all-forecast-detail/wireline-all-forecast-detail.component';
import { WirelineAllForecastSummaryComponent } from './components/wireline-all-forecasts/components/wireline-all-forecast-summary/wireline-all-forecast-summary.component';
import { WirelineAllSalesLeadQuotasComponent } from './components/wireline-sales-lead-quota/components/wireline-all-sales-lead-quotas/wireline-all-sales-lead-quotas.component';
import { WirelineBookOfBusinessSummaryComponent } from './components/reports/forecasting/book-of-business-summary/wireline-book-of-business-summary/wireline-book-of-business-summary.component';
import { WirelineNoteDialogComponent } from './components/wireline-note-dialog/wireline-note-dialog.component';
import { WirelineResidualTrueupsComponent } from './components/wireline-trueups/components/wireline-residual-trueups/wireline-residual-trueups.component';
import { WirelineSalesLeadQuotaInformationComponent } from './components/wireline-sales-lead-quota/components/wireline-sales-lead-quota-information/wireline-sales-lead-quota-information.component';
import { WirelineTrueupsContainerComponent } from './components/wireline-trueups/components/wireline-trueups-container/wireline-trueups-container.component';
import { WirelineUpdateSalesLeadQuotaComponent } from './components/wireline-sales-lead-quota/components/wireline-update-sales-lead-quota/wireline-update-sales-lead-quota.component';
import { WirelineUpfrontTrueUpsComponent } from './components/wireline-trueups/components/wireline-upfront-trueups/wireline-upfront-trueups.component';
import { WirelineYtdForecastResultsComponent } from '@shared/components/ytd-forecast-results/components/wireline-ytd-forecast-results/wireline-ytd-forecast-results.component';
import { YtdForecastResultsContainerComponent } from '@shared/components/ytd-forecast-results/components/ytd-forecast-results-container/ytd-forecast-results-container.component';

// Import Directives
import { DisableFormControlDirective } from './directives/disable-form-control.directive';
import { EditModeDirective } from './components/editable/edit-mode.directive';
import { EditOnEnterDirective } from './components/editable/edit-on-enter.directive';
import { FocusOnEnterDirective } from './directives/focus-on-enter.directive';
import { TrackCapsDirective } from './directives/track-caps.directive';
import { ViewModeDirective } from './components/editable/view-mode.directive';

// Import Pipes
import { FormatTimePipe } from './pipes/format-time.pipe';
import { TimeStringPipe } from './pipes/time-string.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';

@NgModule({
  declarations: [
    AddCompanyDialogComponent,
    AdvancedFiltersDialogComponent,
    ApplicationPortalComponent,
    ColumnSelectDialogComponent,
    CompanySelectComponent,
    CountrySelectComponent,
    DialogComponent,
    DisableFormControlDirective,
    EditableComponent,
    EditModeDirective,
    EditOnEnterDirective,
    FormatTimePipe,
    MobilityAllForecastContainerComponent,
    MobilityAllForecastDetailComponent,
    MobilityAllForecastSummaryComponent,
    PodComponent,
    ProductSelectComponent,
    SnackbarComponent,
    SpeedSelectComponent,
    StateSelectComponent,
    TabGroupComponent,
    TimeStringPipe,
    UserSelectComponent,
    ViewModeDirective,
    WirelineAllForecastContainerComponent,
    WirelineAllForecastDetailComponent,
    WirelineAllForecastSummaryComponent,
    WirelineNoteDialogComponent,
    ReportContainerComponent,
    WirelineUpfrontTrueUpsComponent,
    WirelineYtdForecastResultsComponent,
    MobilityYtdForecastResultsComponent,
    YtdForecastResultsContainerComponent,
    UnsavedChangesDialogComponent,
    CustomerManagementComponent,
    AddVendorComponent,
    AllVendorsComponent,
    UpdateVendorComponent,
    VendorInformationComponent,
    AddCompanyContactComponent,
    CompanyContactInformationComponent,
    AddCompanyAddressComponent,
    ToastrNotificationComponent,
    SimpleChangesDialogComponent,
    AllCustomerOrdersComponent,
    CustomerOrderStatusNoteDialogComponent,
    AddCustomerOrderComponent,
    CustomerOrderInformationComponent,
    UpdateCustomerOrderComponent,
    UpdateCompanyContactComponent,
    AllCompanyContactsComponent,
    NightlyShipmentsReportComponent,
    OrdersWithoutTrackingNumberComponent,
    WirelineUpdateSalesLeadQuotaComponent,
    MobilityUpdateSalesLeadQuotaComponent,
    CopyForecastEntryComponent,
    WirelineAllSalesLeadQuotasComponent,
    WirelineAddSalesLeadQuotaComponent,
    WirelineSalesLeadQuotaInformationComponent,
    MobilityAllSalesLeadQuotasComponent,
    MobilityAddSalesLeadQuotaComponent,
    MobilitySalesLeadQuotaInformationComponent,
    SalesToInvoiceComponent,
    ReassignForecastEntryComponent,
    BookOfBusinessSummaryContainerComponent,
    WirelineBookOfBusinessSummaryComponent,
    MobilityBookOfBusinessSummaryComponent,
    WirelineAllForecastsReportComponent,
    MobilityAllForecastsReportComponent,
    YesNoPipe,
    OutgoingSerialNumbersReportComponent,
    IncomingSerialNumbersReportComponent,
    ShippingChargesReportComponent,
    InvoiceReportComponent,
    TrackCapsDirective,
    AllVendorContactsComponent,
    AddVendorContactComponent,
    UpdateVendorContactComponent,
    VendorContactInformationComponent,
    VendorManagementComponent,
    AllVendorAddressesComponent,
    AddVendorAddressComponent,
    ShipmentTrackingHistoryComponent,
    OrderTypeCountsComponent,
    RequestsReceivedComponent,
    CurrentInventoryComponent,
    SummaryComponent,
    WeeklyOrdersComponent,
    TimeFromManagerApprovedToEndUserReceivedComponent,
    SlaComponent,
    TimeFromManagerApprovedToIntratemShippedComponent,
    ConfirmDeleteDialogComponent,
    FocusOnEnterDirective,
    WirelineTrueupsContainerComponent,
    WirelineResidualTrueupsComponent,
    ActivationsComponent,
    UploadAlliedActivationDataComponent,
    ReceivedQuantityMismatchDialogComponent,    
    SerialNumberSearchComponent,
    OnHandInventorySearchComponent,
    AllGoliathProductToQuotewerksProductMapsComponent,
    AddGoliathProductQuotewerksProductMapComponent,
    GoliathProductQuotewerksProductMapInformationComponent,
    UpdateGoliathProductQuotewerksProductMapComponent,
    InventoryCountReportComponent,
    InventoryCountDetailReportComponent,
    AllStagedSalesOrdersComponent,
    GenerateSalesOrderComponent,
    StagedSalesOrderDetailHistoryComponent,
    AllCompanyProductTagsComponent,
    AddCompanyProductTagComponent,
    ProductProcurementInformationReportComponent,
    AllProductProcurementInformationComponent,
    UpdateProductProcurementInformationComponent,
    AllWirelineForecastCutoffDatesComponent,
    AllMobilityForecastCutoffDatesComponent,
    UpdateMobilityForecastCutoffDateComponent,
    UpdateWirelineForecastCutoffDateComponent,
    UpdateStagedSalesOrderComponent,
    AllGiftBasketsComponent,
    AddGiftBasketComponent,
    UpdateGiftBasketComponent,
    GiftBasketInformationComponent,
    StatesLookupComponent,
    CompaniesLookupComponent,
    UsersLookupComponent,
    CountriesLookupComponent,
  ],
  imports: [
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    OverlayModule,
    PortalModule,
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSnackBarModule,
    MatInputModule,
    DragDropModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    RouterModule,
    MatDividerModule,
    NgChartsModule
  ],
  exports: [
    // Components
    CompanySelectComponent,
    CountrySelectComponent,
    DialogComponent,
    EditableComponent,
    ProductSelectComponent,
    ReportContainerComponent,
    SpeedSelectComponent,
    StateSelectComponent,
    TabGroupComponent,
    UserSelectComponent,
    AllStagedSalesOrdersComponent,

    // Directives
    DisableFormControlDirective,
    EditModeDirective,
    EditOnEnterDirective,
    FocusOnEnterDirective,
    TrackCapsDirective,
    ViewModeDirective,

    // Modules
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    OverlayModule,
    PortalModule,
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSnackBarModule,
    MatInputModule,
    DragDropModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    RouterModule,
    MatDividerModule,
    NgChartsModule,

    // Pipes
    FormatTimePipe,
    TimeStringPipe,
    YesNoPipe
  ]
})
export class SharedModule { }
