// Import Modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Components
import { AccessForbiddenComponent } from './components/access-forbidden/access-forbidden.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

// Import Guards
import { AdminGuard } from '@guards/admin.guard';
import { AlliedUniversalGuard } from '@guards/allied-universal.guard';
import { AuthGuard } from '@guards/auth.guard';
import { FulfillmentGuard } from '@guards/fulfillment.guard';
import { MobilityGuard } from '@guards/mobility.guard';
import { ProcurementGuard } from '@guards/procurement.guard';
import { SteveStuffGuard } from '@guards/steve-stuff.guard';
import { SysAdminGuard } from '@guards/sysadmin.guard';
import { WirelineGuard } from '@guards/wireline.guard';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'wireline',
    loadChildren: () => import('@wireline/wireline.module').then(w => w.WirelineModule),
    canLoad: [
      AuthGuard,
      WirelineGuard
    ]
  },
  {
    path: 'fulfillment',
    loadChildren: () => import('@fulfillment/fulfillment.module').then(f => f.FulfillmentModule),
    canLoad: [
      AuthGuard,
      FulfillmentGuard
    ]
  },
  {
    path: 'mobility',
     loadChildren: () => import('@mobility/mobility.module').then(m => m.MobilityModule),
     canLoad: [
      AuthGuard,
      MobilityGuard
    ]
  },
  {
    path: 'steves-stuff',
    loadChildren: () => import('@stevesStuff/steves-stuff.module').then(ss => ss.StevesStuffModule),
    canLoad: [
      AuthGuard,
      SteveStuffGuard
    ]
  },
  {
    path: 'sysadmin',
    loadChildren: () => import('@sysadmin/sysadmin.module').then(sa => sa.SysAdminModule),
    canLoad: [
      AuthGuard,
      SysAdminGuard
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('@admin/admin.module').then(a => a.AdminModule),
    canLoad: [
      AuthGuard,
      AdminGuard
    ]
  },
  {
    path: 'alliedUniversal',
    loadChildren: () => import('@alliedUniversal/allied-universal.module').then(al => al.AlliedUniversalModule),
    canLoad: [
      AuthGuard,
      AlliedUniversalGuard
    ]
  },
  {
    path: 'procurement',
    loadChildren: () => import('@procurement/procurement.module').then(m => m.ProcurementModule),
    canLoad: [
      AuthGuard,
      ProcurementGuard
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'accessForbidden',
    component: AccessForbiddenComponent
  },
  {
    path: '**', redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
